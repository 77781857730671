import { twMerge } from 'tailwind-merge'

export const LogoIcon = ({ className }: { className?: string }) => (
	<svg
		className={twMerge('inline-block size-9', className)}
		focusable="false"
		aria-hidden="true"
		viewBox="0 0 4782.2 5000"
		data-testid="empiriumLogo"
	>
		<rect fill="currentColor" y="2974.4" width="4782.2" height="819.4" />
		<path
			fill="currentColor"
			d="M819.4,5000h3962.8v-819.4H0c0,452.5,366.8,819.4,819.4,819.4Z"
		/>
		<polygon
			fill="currentColor"
			points="2391.1 238.3 1591.9 1271.3 0 0 0 2587.6 819.4 2587.6 819.4 1703 1719.4 2421.7 2391.1 1566.6 3063.5 2421.1 3962.8 1703 3962.8 2587.6 4782.2 2587.6 4782.2 0 3190.3 1271.3 2391.1 238.3"
		/>
	</svg>
)

import { Link, Outlet } from '@remix-run/react'
import { LogoIcon } from '#app/assets'
import { Footer } from '#app/components/common/footer'
import { bodySize } from '#app/utils'

export default () => (
	<div className="bg-neutral-200 bg-[radial-gradient(#777_1%,_transparent_2%)] bg-[length:100px_100px] bg-[bottom_bottom_100px_100px] text-zinc-800 dark:bg-zinc-900 dark:text-white">
		<header
			className={bodySize({
				class:
					'flex flex-col items-center justify-between gap-4 pt-20 sm:flex-row',
			})}
		>
			<h1 className="font-michroma text-3xl font-bold uppercase">
				<Link to="/" className="flex items-center gap-3">
					<LogoIcon />
					Empirium
				</Link>
			</h1>
			<nav className="flex items-center gap-3">
				{/* TODO: bring back when we're out of stealth mode
				<NavLink
					to="/about"
					className={({ isActive }) =>
						twMerge(
							isActive && 'font-semibold',
							'text-gray-600 hover:text-gray-800',
						)
					}
					prefetch="viewport"
				>
					About
				</NavLink>
							*/}
				{/* TODO: bring back when we have openings
        <NavLink
          to="/careers"
          className={({ isActive }) =>
            twMerge(
              isActive && 'font-semibold',
              'text-gray-600 hover:text-gray-800',
            )
          }
          prefetch="intent"
        >
          Careers
        </NavLink> */}
				{/* TODO: bring back when we have a real contact form
        <NavLink
          to="/waitlist"
          className={({ isActive }) =>
            twMerge(
              isActive && 'font-semibold',
              'text-gray-600 hover:text-gray-800',
            )
          }
          prefetch="intent"
        >
          Contact
        </NavLink> */}
			</nav>
		</header>
		<main>
			<Outlet />
		</main>
		<Footer />
	</div>
)

import { Link } from '@remix-run/react'
import { Linkedin } from 'lucide-react'
import { bodySize } from '#app/utils'

export const Footer = () => {
	return (
		<footer className="bg-zinc-900 text-white dark:bg-black">
			<div
				className={bodySize({
					class:
						'flex flex-col justify-between px-10 py-14 md:flex-row md:items-center md:px-20',
				})}
			>
				<section className="mb-4 md:mb-0">
					<div>
						<h2 className="mb-7 font-michroma text-2xl uppercase">
							<Link to="/">Empirium</Link>
						</h2>
						<p className="text-2xl">Secure Your</p>
						<p className="text-2xl">Critical Supply Chain</p>
					</div>
					<nav className="mt-10 flex flex-col gap-4 text-xs md:mt-16 md:flex-row md:items-center">
						<p>&copy; 2024 Empirium</p>
						<div className="flex gap-x-4">
							<p className="flex flex-col">
								<span>All rights reserved</span>
								<a
									href="mailto:info@empirium.co"
									target="_blank"
									rel="noreferrer"
									className="underline"
								>
									info@empirium.co
								</a>
							</p>
							<p className="flex flex-col">
								<Link to="/privacy" className="underline">
									Privacy Policy
								</Link>
								<Link to="/terms" className="underline">
									Terms of Service
								</Link>
							</p>
						</div>
					</nav>
				</section>

				<nav>
					<ul className="flex gap-6">
						<li>
							<a
								href="https://www.linkedin.com/company/empirium-inc/"
								target="_blank"
								rel="noreferrer"
								className="flex place-content-center rounded-md bg-white p-1"
							>
								<Linkedin fill="black" stroke="black" />
							</a>
						</li>
						{/* <li>
							<a
								href="https://github.com/empiriumco"
								target="_blank"
								rel="noreferrer"
								className="flex place-content-center rounded-full bg-white p-1"
							>
								<Github fill="black" stroke="black" />
							</a>
						</li> */}
					</ul>
				</nav>
			</div>
		</footer>
	)
}
